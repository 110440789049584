
.hero{
	background: url("./assets/images/yunafotoshooting1-4.jpg");
	background-size: cover;
    background-repeat: no-repeat;
	background-position: center;
}
.heroabout{
	background: url("./assets/images/yunashooting3-6.jpg");
	background-size: cover;
    background-repeat: no-repeat;
	background-position: center;
}
.herocontact{
	background: url("./assets/images/yuna3-1.jpg");
	background-size: cover;
    background-repeat: no-repeat;
	background-position: center;
}
.hero-inner{
	padding-top: 50%;
}
.header-nav a:not(.button) {
    color: #ffffff;

}
.footer-social a svg{
	fill: #ffffff !important;
}
.text-color-primary{
	color: #FFC383;
}
body{
	font-family: 'Open Sans';
	font-weight: 100;
	font-size: 14px;
	line-height: 25px;
}
h1{
	font-family: BetterGrade;
	font-size: 120px;
	line-height: 100px;
	letter-spacing: 5px;
}
h1, h2, h3{
	color: #FFC383;
}
h2{
	font-family: BetterGrade;
	font-size: 82px;
	letter-spacing: 5px;
	line-height: 0.85;
}
h3{
	font-family: BetterGrade;
	font-size: 54px;
	letter-spacing: 5px;
	line-height: 0.85;
}
.fw-600 {
    font-weight: 100;
}
.socialMetrics .tiles-item-inner {
    background: #25282C;
}
.socialMediaNumber{
	font-size: 38px;
	color: #FFC383;
}
.sm-item-content{
	padding-top: 15px;
	text-align: center;
}
.sm-item-content .socialMediaNumber{
	padding-bottom: 10px;
}
.header-nav a:not(.button) {
    font-weight: 100;
	color: #FFC383;
}
.footer-nav a {
    color: #FFC383;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    background: #FFC383;
}
@media(min-width: 641px){
	h2{
		font-size: 70px;
	}
}
@media(max-width: 640px){
	h1{
		font-size: 82px;
	}
	h2{
		font-size: 42px;
	}
	h3{
		font-size: 42px;
	}
	.footer-social{
		display: none;
	}
}